<script lang="ts">
	import Loading from './Loading.svelte';

	export let icon: string = '';
	export let text: string = '';
	export let disabled: boolean = false;
	export let isLoading: boolean = false;
	export let type: 'primary' | 'tertiary' | 'error' | 'secondary' | 'surface' | 'success' =
		'primary';
	const classMap = {
		primary: 'variant-filled-primary',
		tertiary: 'variant-filled-tertiary',
		error: 'variant-filled-error',
		secondary: 'variant-filled-secondary',
		surface: 'variant-filled-surface',
		success: 'variant-filled-success'
	};
</script>

<button
	type="button"
	class="btn custom-button {classMap[type]} font-bold w-full text-sm {$$props.class} "
	disabled={isLoading || disabled}
	on:click
	on:submit
>
	{#if isLoading}
		<Loading width="w-5" />
	{:else}
		{#if icon}
			<span>(icon)</span>
		{/if}
		<span>{text}</span>
	{/if}
</button>

<style>
	.custom-button:disabled {
		opacity: 0.8;
	}
</style>
